<template>
  <div class="city-view">
    <div class="city-view__wrapper">
      <Section class="city-view__image-holder"
               limiter>
        <img class="city-view__image"
             :src="$city.image"
             alt/>
        <Column class="city-view__meta">
          <BreadCrumbs class="city-view__bread-crumbs"
                       :params="{navigation, color: 'rgba(255,255,255,.4)'}"/>
          <h1 class="city-view__title">{{ $city.title }}</h1>
          <div class="city-view__description grid-lg-8" v-html="$city.description"/>
          <Row class="city-view__anchors -m-h-5"
               :wrap="$mobile">
            <Anchor v-for="anchor in anchors"
                    :key="anchor.selector"
                    :params="anchor"/>
          </Row>
        </Column>
      </Section>
      <Section>
        <ReasonsVisitArea id="ReasonsVisitArea">
          <template slot="reasons-visit-area-title">
            <h2 class="reasons-visit-area__title">{{ $t('city_view.visit_reasons') }}
              {{ $city.name }}
            </h2>
          </template>
        </ReasonsVisitArea>
      </Section>
      <Section v-if="photos && photos.length > 0">
        <Gallery id="Gallery"
                 :params="{title: `${$t('city_view.photos')} ${ city.genitive }`,
                items: photos}"/>
      </Section>
      <Section>
        <ThematicSlider id="ThematicSliderNews"
                        :params="{array: $news.data,
            component: 'NewsCard',
            count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('city_view.news') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
                 class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                           :to="`${$locale.base}/news`">
                {{ $laptop ? $t('home.watch_all.p_1') : '' }}
                {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                    viewport="0 0 20 20"
                    :rotate="90"
                    xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <!--      <div class="home-view__black-section">-->
      <!--        <Section>-->
      <!--          <ThematicSlider id="ThematicSliderStories"-->
      <!--            :params="{array: $stories.data.filter(item => item.type !== 'panel'),-->
      <!--              component: 'Story',-->
      <!--              count: 4}">-->
      <!--            <template slot="thematic-slider-title">-->
      <!--<h2 class="thematic-slider__title">{{ $t('city_view.city_stories') }}</h2>-->
      <!--            </template>-->
      <!--            <template slot="thematic-slider-button">-->
      <!--              <Row align="center"-->
      <!--                class="thematic-slider__see-all">-->
      <!--                <router-link class="thematic-slider__see-all-text"-->
      <!--                  :to="`${$locale.base}/stories`">-->
      <!--{{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}-->
      <!--                </router-link>-->
      <!--                <Icon class="thematic-slider__see-all-icon"-->
      <!--                  viewport="0 0 20 20"-->
      <!--                  :rotate="90"-->
      <!--                  xlink="arrow-navigation"/>-->
      <!--              </Row>-->
      <!--            </template>-->
      <!--          </ThematicSlider>-->
      <!--        </Section>-->
      <!--      </div>-->
      <Section v-if="$showIfDefaultCity"
               :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/mobile.jpg') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.app.p_1') }} <br>
              {{ $t('home.app.p_2') }}</span>
          </template>
          <component :is="$mobile ? 'Column' : 'Row'"
                     :full="$mobile"
                     :class="$mobile ? '' : '-m-15'">
            <a class="home-view__app-icon"
               :href="appStoreHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="home-view__app-icon"
               :href="googlePlayHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </component>
        </FormationPortal>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CityView',
  data() {
    return {
      gallery: null,
      photos: [],
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
      anchors: [{
        title: this.$i18n.t('anchors.visit_reasons'),
        selector: '#ReasonsVisitArea',
        indent: 160,
      }, {
        title: this.$i18n.t('anchors.city_photo'),
        selector: '#Gallery',
        indent: 80,
      }, {
        title: this.$i18n.t('anchors.news'),
        selector: '#ThematicSliderNews',
        indent: 160,
      }, {
        title: this.$i18n.t('anchors.stories'),
        selector: '#ThematicSliderStories',
        indent: 160,
      }],
      params: {
        controls: [],
      },
      navigation: [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: this.$route.path,
        title: this.$i18n.t('menu.about_city'),
      }],
    };
  },
  computed: {
    city() {
      return this.$store.getters.GET_CITY.declensions;
    },
    count() {
      let count;
      if (this.$laptop) count = 3;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
  },
  created() {
    this.$http.get(`/api/galleries?city_id=${this.$city.id}`)
    // eslint-disable-next-line no-return-assign,prefer-destructuring
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          this.gallery = response?.data?.data[0];
        }
      })
    // eslint-disable-next-line camelcase
      .then(() => {
        if (this?.gallery) {
          this.$http.get(`/api/galleries/${this?.gallery?.id}`)
          // eslint-disable-next-line no-return-assign
            .then((response) => this.photos = response.data.data.photos.map((item) => item.image));
        }
      });
    this.$store.dispatch('GET_ARTICLES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 100500,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 100500,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_STORIES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 100500,
        lang: this.$i18n.locale,
      },
    });
  },
};
</script>
